import api from '@/services/api/register'
import * as types from '@/store/mutation-types'
import { handleError } from '@/utils/utils.js'

const getters = {
  userId: state => state.userId,

  cpf: state => state.cpf,
  cardLimitTotal: state => state.cardLimitTotal,
  cardLimitLeft: state => state.cardLimitLeft,
  need: state => state.need,

  available: state => state.available,
  installments: state => state.installments,
  selectedAmount: state => state.selectedAmount,
  selectedInstallments: state => state.selectedInstallments,
  selectedInstallmentsFactor: state => state.selectedInstallmentsFactor,
  selectedInstallmentsFee: state => state.selectedInstallmentsFee,
  name: state => state.name,
  email: state => state.email,
  birthday: state => state.birthday,
  motherName: state => state.motherName,
  cellphone: state => state.cellphone,
  gender: state => state.gender,
  monthlyIncome: state => state.monthlyIncome,
  profession: state => state.profession,
  education: state => state.education,

  cep: state => state.cep,
  state: state => state.state,
  street: state => state.street,
  neighborhood: state => state.neighborhood,
  city: state => state.city,
  number: state => state.number,
  complement: state => state.complement,

  pixKeyType: state => state.pixKeyType,
  pixKey: state => state.pixKey,

  accountType: state => state.accountType,
  bank: state => state.bank,
  bankName: state => state.bankName,
  agency: state => state.agency,
  account: state => state.account,
  accountDigit: state => state.accountDigit,
}

const actions = {

    postCpf( { commit }, payload ) {
      commit(types.SHOW_CENTER_LOADING, true)

      return new Promise((resolve, reject) => {
        api
          .postCpf(payload)
          .then(response => {
            if(response.status === 200){
              console.log( JSON.stringify(response,null,2) )

              commit(types.USER_ID, response.data._id)
              commit(types.AVAILABLE, response.data.available)
              commit(types.INSTALLMENTS, response.data.installments.reverse())
              commit(types.SHOW_CENTER_LOADING, false)
              resolve(true)
            }
          }).catch(error => {
            handleError(error, commit, reject)
          })
      })
    },

    updateData( { commit }, payload ) {
      commit(types.SHOW_CENTER_LOADING, true)

      return new Promise((resolve, reject) => {
        api
          .updateData(payload)
          .then(response => {
            if(response.status === 200){
              console.log( JSON.stringify(response,null,2) )
              commit(types.SHOW_CENTER_LOADING, false)
              resolve(true)
            }
          }).catch(error => {
            handleError(error, commit, reject)
          })
      })
    },

    updateDocs( { commit }, payload ) {
      commit(types.SHOW_CENTER_LOADING, true)

      return new Promise((resolve, reject) => {
        api
          .updateDocs(payload)
          .then(response => {
            if(response.status === 200){
              console.log( JSON.stringify(response,null,2) )
              commit(types.SHOW_CENTER_LOADING, false)
              resolve(true)
            }
          }).catch(error => {
            handleError(error, commit, reject)
          })
      })
    },
}

const mutations = {
  [types.USER_ID](state, value) {
    state.userId = value
  },
  [types.CPF](state, value) {
    state.cpf = value
  },
  [types.CARD_LIMIT_TOTAL](state, value) {
    state.cardLimitTotal = value
  },
  [types.CARD_LIMIT_LEFT](state, value) {
    state.cardLimitLeft = value
  },
  [types.NEED](state, value) {
    state.need = value
  },
  [types.AVAILABLE](state, value) {
    state.available = value
  },
  [types.INSTALLMENTS](state, value) {
    state.installments = value
  },
  [types.SELECTED_AMOUNT](state, value) {
    state.selectedAmount = value
  },
  [types.SELECTED_INSTALLMENTS](state, value) {
    state.selectedInstallments = value
  },
  [types.SELECTED_INSTALLMENTS_FACTOR](state, value) {
    state.selectedInstallmentsFactor = value
  },
  [types.SELECTED_INSTALLMENTS_FEE](state, value) {
    state.selectedInstallmentsFee = value
  },
  [types.NAME](state, value) {
    state.name = value
  },
  [types.EMAIL](state, value) {
    state.email = value
  },
  [types.BIRTHDAY](state, value) {
    state.birthday = value
  },
  [types.MOTHER_NAME](state, value) {
    state.motherName = value
  },
  [types.CELLPHONE](state, value) {
    state.cellphone = value
  },
  [types.GENDER](state, value) {
    state.gender = value
  },
  [types.MONTHLY_INCOME](state, value) {
    state.monthlyIncome = value
  },
  [types.PROFESSION](state, value) {
    state.profession = value
  },
  [types.EDUCATION](state, value) {
    state.education = value
  },
  [types.CEP](state, value) {
    state.cep = value
  },
  [types.STATE](state, value) {
    state.state = value
  },
  [types.STREET](state, value) {
    state.street = value
  },
  [types.NEIGHBORHOOD](state, value) {
    state.neighborhood = value
  },
  [types.CITY](state, value) {
    state.city = value
  },
  [types.NUMBER](state, value) {
    state.number = value
  },
  [types.COMPLEMENT](state, value) {
    state.complement = value
  },
  [types.PIX_KEY_TYPE](state, value) {
    state.pixKeyType = value
  },
  [types.PIX_KEY](state, value) {
    state.pixKey = value
  },
  [types.ACCOUNT_TYPE](state, value) {
    state.accountType = value
  },
  [types.BANK](state, value) {
    state.bank = value
  },
  [types.BANK_NAME](state, value) {
    state.bankName = value
  },
  [types.AGENCY](state, value) {
    state.agency = value
  },
  [types.ACCOUNT](state, value) {
    state.account = value
  },
  [types.ACCOUNT_DIGIT](state, value) {
    state.accountDigit = value
  }
}

const state = {
  userId: null,
  cpf: null,
  cardLimitTotal: null,
  cardLimitLeft: null,
  need: null,
  available: null,
  installments: null,
  selectedAmount: null,
  selectedInstallments: null,
  selectedInstallmentsFactor: null,
  selectedInstallmentsFee: null,
  name: null,
  email: null,
  birthday: null,
  motherName: null,
  cellphone: null,
  gender: null,
  monthlyIncome: null,
  profession: null,
  education: null,
  cep: null,
  state: null,
  street: null,
  neighborhood: null,
  city: null,
  number: null,
  complement: null,
  accountType: null,
  bank: null,
  bankName: null,
  agency: null,
  account: null,
  accountDigit: null
}

export default {
  state,
  actions,
  getters,
  mutations
}
