import Vue from 'vue'
import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/auth'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
  user: state => state.user,
  events: state => state.events,
  token: state => state.token,
  isTokenSet: state => state.isTokenSet,
}

const actions = {

  externalLogin ( { commit }, payload ) {

    var decoded = JSON.parse(atob(payload))

    window.localStorage.setItem( 'token', JSON.stringify(decoded.token) )
    window.localStorage.setItem( 'refreshToken', JSON.stringify(decoded.refreshToken) )
    window.localStorage.setItem( 'tokenExpiration', JSON.stringify(decoded.tokenExpiration) )
    window.localStorage.setItem( 'user', JSON.stringify(decoded.user) )

    commit( types.SAVE_TOKEN, decoded.token )
    commit( types.SAVE_USER, decoded.user )
    router.push({ name: 'landing' }, () => {})

  },

  userLogin({ commit }, payload) {

    return new Promise((resolve, reject) => {

      commit(types.SHOW_CENTER_LOADING, true)
      api
        .userLogin(payload)
        .then(response => {

          if (response.status === 200) {

            if(!response.data.data.bank){
              window.localStorage.setItem(
                'token',
                JSON.stringify(response.data.token)
              )
              commit(types.SAVE_TOKEN, response.data.token)
              buildSuccess(
                null,
                commit,
                resolve,
                router.push({
                  name: 'unavailable'
                }, () => {})
              )
            }else{
              window.localStorage.setItem(
                'user',
                JSON.stringify(response.data.data.user)
              )
              window.localStorage.setItem(
                'events',
                JSON.stringify(response.data.data.events)
              )

              window.localStorage.setItem(
                'token',
                JSON.stringify(response.data.token)
              )
              window.localStorage.setItem(
                'refreshToken',
                JSON.stringify(response.data.refreshToken)
              )
              window.localStorage.setItem(
                'tokenExpiration',
                JSON.stringify(Vue.$jwt.decode(response.data.token).exp)
              )

              commit(types.SAVE_USER, response.data.data.user)
              commit(types.SAVE_TOKEN, response.data.token)
              commit(types.SHOW_CENTER_LOADING, false)

              buildSuccess(
                null,
                commit,
                resolve,
                router.push({
                  name: 'landing'
                }, () => {})
              )

            }
          }

        })
        .catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },
  refreshToken({ commit }) {

    commit(types.SHOW_TOP_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .refreshToken({token: JSON.parse(window.localStorage.getItem('refreshToken'))})
        .then(response => {

          if (response.status === 200) {
            window.localStorage.setItem(
              'user',
              JSON.stringify(response.data.data.user)
            )
            window.localStorage.setItem(
              'events',
              JSON.stringify(response.data.data.events)
            )
            window.localStorage.setItem(
              'token',
              JSON.stringify(response.data.token)
            )
            window.localStorage.setItem(
              'tokenExpiration',
              JSON.stringify(Vue.$jwt.decode(response.data.token).exp)
            )
            window.localStorage.setItem(
              'refreshToken',
              JSON.stringify(response.data.refreshToken)
            )

            commit(types.SAVE_USER, response.data.data.user)
            commit(types.SAVE_TOKEN, response.data.token)
            commit(types.SHOW_TOP_LOADING, false)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve()
          }
        })
        .catch(error => {
          console.log('refreshToken ERROR: '+JSON.stringify(error,null,2))
          actions.userLogout({ commit })
          reject(false)
          //handleError(error, commit, reject)
        })
    })
  },

  userLogout({ commit }) {
    console.log('- userLogout -')

    window.localStorage.clear()
    window.sessionStorage.clear()

    commit(types.SHOW_CENTER_LOADING, false)
    commit(types.SHOW_CENTER_LOADING, false)

    commit(types.LOGOUT)
    if(router.currentRoute.name != 'login'){
      router.push({
        name: 'login'
      })
    }

  }
}

const mutations = {
  [types.SAVE_USER](state, value) {
    state.user = value
  },
  [types.SAVE_TOKEN](state, token) {
    state.token = token
    state.isTokenSet = true
  },
  [types.LOGOUT](state) {
    state.user = null
    state.events = null
    state.isTokenSet = false
  }
}

const state = {
  user: null,
  token: JSON.parse(!!localStorage.getItem('token')) || null,
  isTokenSet: !!localStorage.getItem('token')
}

export default {
  state,
  getters,
  actions,
  mutations
}
