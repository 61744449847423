import api from '@/services/api/financial'
import * as types from '@/store/mutation-types'
import { handleError } from '@/utils/utils.js'
import router from '@/router'

const PagarmeKey = process.env.VUE_APP_PAGARME_KEY

const getters = {
  cardHash: state => state.cardHash,
  transactionStatus: state => state.transactionStatus,
  transactionId: state => state.transactionId,
}

const actions = {

  payment({ commit }, payload) {

    commit(types.SHOW_CENTER_LOADING, true)
    commit(types.TRANSACTION_STATUS, null)

    return new Promise((resolve, reject) => {

      var unknownError = {}

      api
        .payment(payload)
        .then(response => {
          if (response.status === 200) {
            // commit(types.TRANSACTION_STATUS, response.data.status)

            if(response.data.status == 'refused') { // se a transação for recusada
              unknownError.response = response

              unknownError.response = {
                data: {
                  errors:
                  { msg: 'refused' }
                }
              }

              handleError(unknownError, commit, reject)
              router.push({ name: 'register-11' })
            }

            commit(types.TRANSACTION_ID, response.data.tid)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)

          }else if(response.data.errors != null){

            commit(types.TRANSACTION_STATUS, response.data.errors.msg)
            unknownError.response = response
            unknownError.response.data.errors.msg = unknownError.response.data.errors.msg.split('.')[0]
            handleError(unknownError, commit, reject)
          }

        }).catch(error => {
          commit(types.TRANSACTION_STATUS, error.response.data.errors.msg)
          commit(types.SHOW_CENTER_LOADING, false)
        })
    })
  },

  generateHash( {commit}, payload ){

    commit(types.SHOW_CENTER_LOADING, true)

    return new Promise((resolve) => {
      const pagarme = require('pagarme')

      pagarme.client.connect({ encryption_key: PagarmeKey })
      .then(client => {
        return client.security.encrypt(payload)
      })
      .then(card_hash => {
        commit(types.CARD_HASH, card_hash)
        commit(types.SHOW_CENTER_LOADING, false)
        resolve(true)
      })
    })
  }

}

const mutations = {
  [types.CARD_HASH](state, value) {
    state.cardHash = value
  },
  [types.TRANSACTION_STATUS](state, value) {
    state.transactionStatus = value
  },
  [types.TRANSACTION_ID](state, value) {
    state.transactionId = value
  }
}

const state = {
  cardHash: null,
  transactionStatus: null,
  transactionId: null,
  slipUrl: null,
  pixQR: null,
  pixQRCode: null,
  checkoutTotal: 0,
  checkoutLiquid: 0
}

export default {
  state,
  getters,
  actions,
  mutations
}
