export const SAVE_USER = 'SAVE_USER'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const LOGOUT = 'LOGOUT'

export const DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED'
export const UPLOAD_PERCENT = 'UPLOAD_PERCENT'

export const ERROR = 'ERROR'
export const SUCCESS = 'SUCCESS'

export const SHOW_ERROR = 'SHOW_ERROR'
export const SHOW_SUCCESS = 'SHOW_SUCCESS'

export const FILES = 'FILES'
export const SHOW_BTN_LOADING = 'SHOW_BTN_LOADING'
export const SHOW_BUFFER_LOADING = 'SHOW_BUFFER_LOADING'
export const SHOW_CENTER_LOADING = 'SHOW_CENTER_LOADING'
export const SHOW_TOP_LOADING = 'SHOW_TOP_LOADING'

export const USER_ID = 'USER_ID'

export const CPF = 'CPF'
export const CARD_LIMIT_TOTAL = 'CARD_LIMIT_TOTAL'
export const CARD_LIMIT_LEFT = 'CARD_LIMIT_LEFT'
export const NEED = 'NEED'
export const AVAILABLE = 'AVAILABLE'
export const INSTALLMENTS = 'INSTALLMENTS'
export const SELECTED_AMOUNT = 'SELECTED_AMOUNT'
export const SELECTED_INSTALLMENTS = 'SELECTED_INSTALLMENTS'
export const SELECTED_INSTALLMENTS_FACTOR = 'SELECTED_INSTALLMENTS_FACTOR'
export const SELECTED_INSTALLMENTS_FEE = 'SELECTED_INSTALLMENTS_FEE'
export const NAME = 'NAME'
export const EMAIL = 'EMAIL'
export const BIRTHDAY = 'BIRTHDAY'
export const MOTHER_NAME = 'MOTHER_NAME'
export const CELLPHONE = 'CELLPHONE'
export const GENDER = 'GENDER'

export const MONTHLY_INCOME = 'MONTHLY_INCOME'
export const PROFESSION = 'PROFESSION'
export const EDUCATION = 'EDUCATION'

export const CEP = 'CEP'
export const STATE = 'STATE'
export const STREET = 'STREET'
export const NEIGHBORHOOD = 'NEIGHBORHOOD'
export const CITY = 'CITY'
export const NUMBER = 'NUMBER'
export const COMPLEMENT = 'COMPLEMENT'

export const PIX_KEY_TYPE = 'PIX_KEY_TYPE'
export const PIX_KEY = 'PIX_KEY'

export const ACCOUNT_TYPE = 'ACCOUNT_TYPE'
export const BANK = 'BANK'
export const BANK_NAME = 'BANK_NAME'
export const AGENCY = 'AGENCY'
export const ACCOUNT = 'ACCOUNT'
export const ACCOUNT_DIGIT = 'ACCOUNT_DIGIT'

export const TRANSACTION_STATUS = 'TRANSACTION_STATUS'
export const TRANSACTION_ID = 'TRANSACTION_ID'

export const CARD_HASH = 'CARD_HASH'
