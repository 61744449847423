import axios from 'axios'

export default {

  postCreditCard(payload) {
    delete payload['cardData']
    return axios.post('/pagarme/credit-card/', payload)
  },
  getCardHash(params) {
    return axios.get(`/pagarme/card-hash/${params.id}`)
  },

  payment(params) {
    return axios.patch(`/bank/promoter/request/pay`, params)
  }


}
