export default [
  {
    path: '/register',
    name: 'register',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register01.vue')
  },
  {
    path: '/register02',
    name: 'register-02',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register02.vue')
  },
  {
    path: '/register03',
    name: 'register-03',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register03.vue')
  },
  {
    path: '/register04',
    name: 'register-04',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register04.vue')
  },
  {
    path: '/register05',
    name: 'register-05',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register05.vue')
  },
  {
    path: '/register06',
    name: 'register-06',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register06.vue')
  },
  {
    path: '/register07',
    name: 'register-07',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register07.vue')
  },
  {
    path: '/register08',
    name: 'register-08',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register08.vue')
  },
  {
    path: '/register09',
    name: 'register-09',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register09.vue')
  },
  {
    path: '/register10',
    name: 'register-10',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register10.vue')
  },
  {
    path: '/register11',
    name: 'register-11',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register11.vue')
  },
  {
    path: '/register12',
    name: 'register-12',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Register12.vue')
  }
]
