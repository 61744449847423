import api from '@/services/api/files'

import * as types from '@/store/mutation-types'
import { handleError } from '@/utils/utils.js'

const getters = {
  documentUploaded: state => state.documentUploaded,
  uploadPercent: state => state.uploadPercent,
  files: state => state.files
}

const actions = {

  async getUploadUrl({ commit }, payload) {
    commit(types.SHOW_CENTER_LOADING, true)
    try {
      const response = await api.getXhrUrl(payload)
      return response.data.url
    } catch (error) {
      commit(types.SHOW_CENTER_LOADING, false)
      console.log('error', error)
      handleError(error, commit)
    }
  },
  async newUploadFile({ commit }, params) {    
    
    commit(types.SHOW_BUFFER_LOADING, true)
    
    return new Promise((resolve, reject) => {
      try {

        const xhr = new XMLHttpRequest()
        xhr.open(params.op, params.url)

        xhr.upload.onprogress = function(event) {            
            if (event.loaded) {                
                const percentage = (event.loaded / event.total) * 100
                commit(types.UPLOAD_PERCENT, percentage) 
            }
        }

        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 300) {
                
              commit(types.DOCUMENT_UPLOADED, true)
              commit(types.SHOW_BUFFER_LOADING, false)
              commit(types.SHOW_BTN_LOADING, false)
              commit(types.UPLOAD_PERCENT, 0)
              
              resolve(true)

            } else {
                console.log('Falha no upload')

                commit(types.SHOW_BUFFER_LOADING, false)
                commit(types.DOCUMENT_UPLOADED, false)
                commit(types.SHOW_BTN_LOADING, false)
                commit(types.UPLOAD_PERCENT, 0)

                handleError(xhr.statusText, commit, reject)
                reject(false)
            }
        }

        xhr.onerror = function() {
            console.error('Erro no upload:', xhr.statusText)
        }

        xhr.send(params.formData)
      } catch (error) {
        console.error('Erro no upload:', error)
      }
    })
  },

}

const mutations = {
  [types.DOCUMENT_UPLOADED](state, value) {
    state.documentUploaded = value
  },
  [types.UPLOAD_PERCENT](state, value) {
    state.uploadPercent = value
  },
  [types.FILES](state, value) {
    state.files = value
  }
}

const state = {
  bannerUploaded: false,
  uploadPercent: 0,
  documentUploaded: false,
  files: ''
}

export default {
  state,
  getters,
  actions,
  mutations
}
