import axios from 'axios'

export default {

  postCpf(payload) {
    return axios.post('/bank/promoter/request', payload)
  },
  updateData(params) {
    return axios.patch(`/bank/promoter/request`, params)
  },
  updateDocs(params) {
    return axios.patch(`/bank/promoter/request/docs`, params)
  }


    // {
    //     "_id": "62a791b16fea2a472d89d85a",
    //     "docs": {
    //         "type": "identidade",
    //         "file": "629e6313edf7c75f004d0cc0/IMG_0440.jpg"
    //     }
    // }


    // {
    //     "cpf": "06268472659",
    //     "cardLimitTotal": 1000,
    //     "cardLimitLeft": 300,
    //     "reason": "Compras"
    // }

}
