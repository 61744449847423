import * as types from '@/store/mutation-types'

const getters = {
  showTopLoading: state => state.showTopLoading,
  showBtnLoading: state => state.showBtnLoading,
  showCenterLoading: state => state.showCenterLoading,
  showBufferLoading: state => state.showBufferLoading
}

const mutations = {
  [types.SHOW_TOP_LOADING](state, value) {
    state.showTopLoading = value
  },
  [types.SHOW_BTN_LOADING](state, value) {
    state.showBtnLoading = value
  },
  [types.SHOW_CENTER_LOADING](state, value) {
    state.showCenterLoading = value
  },
  [types.SHOW_BUFFER_LOADING](state, value) {
    state.showBufferLoading = value
  }
}

const state = {
  showTopLoading: false,
  showBtnLoading: false,
  showCenterLoading: false,
  showBufferLoading: false
}

export default {
  state,
  getters,
  mutations
}
