export default [
  {
    path: '/unavailable',
    name: 'unavailable',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Unavailable.vue')
  }
]
