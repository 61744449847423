import axios from 'axios'

export default {
  getXhrUrl(params){
    console.log('oi', JSON.stringify( params,null,2 ) )
    return axios.get(`/events/promoter/upload-file?op=${params.op}&bucket=${params.cdn}&key=${params.bucket}/${params.filename}`)
  },
  xhrCopy(params){
    return axios.get(`/events/promoter/upload-file?op=${params.op}&bucket=${params.cdn}&key=${params.bucket}/${params.filename}&keyTo=${params.keyTo}/${params.filename}`)
  }
}