<template lang="pug">
  v-app
    v-app-bar(app color="white" dark)
      div(class="d-flex align-center")
        router-link(:to="{ name: 'landing' }")
          v-img(alt="Logo" class="shrink mr-2" contain :src="`${imgPath}/site/imgs/logo-ifraldas.png`" transition="scale-transition" width="140")
      v-spacer
      v-btn(depressed color="primary" rounded @click="howItWorks=!howItWorks" class="text-capitalize") Como funciona?
    v-main
      v-dialog(v-model="howItWorks" max-width="420px")
        v-card
          v-card-title(style="word-break: normal !important;" class="accent--text font-weight-bold") Como funciona o emprestimo iFraldas?
          v-card-text
            v-row
              v-col
                v-stepper(v-model="how" class="elevation-0")
                  v-stepper-items
                    v-stepper-content(step="1")
                      v-row
                        v-col
                          p(class="title font-weight-bold buttons--text") 1º: Solicitação
                          p
                            v-img(:src="`${imgPath}/credito/como-funciona-01.png`" :lazy-src="`${imgPath}/credito/como-funciona-01.png`" aspect-ratio="1" width="200" style="margin: auto;")
                          p(class="body-1 font-weight-regular accent--text") Faça seu pedido enviando todas as solicitações necessárias em menos de 5 minutos
                      v-btn(color="primary" @click="how = 2" class="text-capitalize" rounded block depressed) Continue
                    v-stepper-content(step="2")
                      v-row
                        v-col
                          p(class="title font-weight-bold buttons--text") 2º: Parcelamento
                          p
                            v-img(:src="`${imgPath}/credito/como-funciona-02.png`" :lazy-src="`${imgPath}/credito/como-funciona-02.png`" aspect-ratio="1" width="200" style="margin: auto;")
                          p(class="body-1 font-weight-regular accent--text") É obrigatório utilizar seu cartão de crédito para fazer o pagamento das prestações
                      v-btn(color="primary" @click="how = 3" class="text-capitalize" rounded block depressed) Continue
                    v-stepper-content(step="3")
                      v-row
                        v-col
                          p(class="title font-weight-bold buttons--text") 3º: Análise
                          p
                            v-img(:src="`${imgPath}/credito/como-funciona-03.png`" :lazy-src="`${imgPath}/credito/como-funciona-03.png`" aspect-ratio="1" width="200" style="margin: auto;")
                          p(class="body-1 font-weight-regular accent--text") Vamos verificar seu cadastro e toda a documentação enviada. Esse processo pode demorar até 1 dia útil
                      v-btn(color="primary" @click="how = 4" class="text-capitalize" rounded block depressed) Continue
                    v-stepper-content(step="4")
                      v-row
                        v-col
                          p(class="title font-weight-bold buttons--text") 4º: Liberação
                          p
                            v-img(:src="`${imgPath}/credito/como-funciona-04.png`" :lazy-src="`${imgPath}/credito/como-funciona-04.png`" aspect-ratio="1" width="200" style="margin: auto;")
                          p(class="body-1 font-weight-regular accent--text") Após aprovação na análise de crédito, você receberá o valor solicitado em até 24 horas
                      v-btn(color="primary" @click="howItWorks=false" class="text-capitalize" rounded block depressed) Entendi!
      router-view(@clicked="showHowItWorks()")
      CenterLoading
</template>

<script>

import CenterLoading from './components/core/CenterLoading'

export default {
  name: 'App',
  components: { CenterLoading },
  data: () => ({
    imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
    howItWorks: false,
    how: 1
  }),
  methods: {
    showHowItWorks() {
      this.howItWorks = true
    }
  },
  watch: {
    howItWorks(val){
      if(!val){ this.how = 1 }
    }
  }
};
</script>
<style>
  .main-container{
    height: 100%;
    background-color: #55CDC7 !important;
  }
  .bg-terms {
    background-color: white !important;
  }
  .smallFont {
    font-size: 10px;
  }
  .checkboxFont .v-label{
    font-size: 12px !important;
  }
  .selected {
    font-weight: bold;
    color: #55CDC7 !important;
  }
</style>
