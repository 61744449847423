import router from '@/router'

export default [
  {
    path: '/log',
    name: 'log',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/components/Log.vue'),
    meta: {
      title: 'Validando'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/components/Login.vue'),
    meta: {
      title: 'Login'
    },
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/components/Logout.vue'),
    meta: {
      title: 'Saindo...'
    }
  },
]

const guard = async function(to, from, next) {
  var user = JSON.parse(window.localStorage.getItem('user'))
  if ( user !== null && (user.role == 'promoter')) {
    router.push({
      name: 'landing'
    }, () => {})
  }else if ( user !== null && user.role == 'admin') {
    router.push({
      name: 'logout'
    }, () => {})
  }else{
    return next()
  }
}
