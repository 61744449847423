import Vue from 'vue'
import axios from 'axios'
import { store } from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
  config => {

    const urlsExcludedForBearerHeader = [
      'https://viacep.com.br/ws/',
      '/login',
      `${window.location.origin}/version.json`
    ]
    if (urlsExcludedForBearerHeader.indexOf(config.url) === -1) {
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem('token')
      )}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  // erro diferente de 401 - apeas retorna
  if (error.response.status !== 401) {
    return Promise.reject(error)
  }

  console.log("ERRO STATUS:: "+error.response.status)
  console.log("ERRO URL:: "+error.config.url)
  // Se foi o refreshToken que retornou erro 401, desloga o usuário
  if (error.config.url == '/refresh-token/') {
    console.log('refreshToken falhou: '+JSON.stringify(error,null,2))
    store.dispatch('userLogout')
    return new Promise((resolve, reject) => {
      reject(error);
    })
  }

  // guarda a requisição original
  const originalRequest = error.config

  // se deu erro 401 e não é uma segunda tentativa
  if (error.config.url != '/login' && !originalRequest._retry) {

      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject}) // guarda requisição na fila
        }).then(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          return axios(originalRequest) // tenta novamente com o token
        }).catch(err => {
          return Promise.reject(err)
        })
      }


    originalRequest._retry = true
    isRefreshing = true

    return new Promise(function (resolve, reject) {
      return store.dispatch('refreshToken')
      .then(() => {
            var token = JSON.parse( localStorage.getItem('token') )
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            originalRequest.headers.Authorization = `Bearer ${token}`
            processQueue(null, token)
            resolve(axios(originalRequest))
        })
        .catch((err) => {
            processQueue(err, null)
            reject(err)
        })
        .finally(() => { isRefreshing = false })
    })
  }else{
    // caso contrário apenas retorna o erro
    return new Promise((resolve, reject) => {
      reject(error);
    })
  }

})


Plugin.install = Vue => {
  Vue.axios = axios
  window.axios = axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios
      }
    },
    $axios: {
      get() {
        return axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
