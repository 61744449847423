export default [
  {
    path: '/finish',
    name: 'finish',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/Finish.vue')
  }
]
