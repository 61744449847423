export default [
  {
    path: '/uploadid',
    name: 'upload-id',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/UploadId.vue')
  },
  {
    path: '/uploadcard',
    name: 'upload-card',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Register/UploadCard.vue')
  }
]
