import axios from 'axios'

export default {
  userLogin(payload) {
    return axios.post('/login', payload)
  },
  refreshToken(payload) {
    return axios.post('/refresh-token/', payload)
  }
}
