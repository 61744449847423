export default [
  {
    path: '/cancellation-policy',
    name: 'cancellation-policy',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Terms/CancellationPolicy.vue')
  },
  {
    path: '/analysis-policy',
    name: 'analysis-policy',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Terms/AnalysisPolicy.vue')
  },
  {
    path: '/use-terms',
    name: 'use-terms',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Terms/UseTerms.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/Terms/PrivacyPolicy.vue')
  }
]
